<template>
  <div class="course-box">
    <div class="operate-box">
      <el-button type="primary" style="margin-bottom: 20px" @click="$router.go(-1)">返回</el-button>
    </div>
    <el-table :data="list" style="width: 100%; flex: 1" height="1%" class="customTable"
              :header-cell-style="{ fontWeight: 'normal', color: '#332D42', background: '#F6F6F6', height: '60px' }"
              :cell-style="{ fontSize: '14px', color: '#332D42', height: '70px' }">
      <el-table-column prop="name" label="作业账号" width="230"></el-table-column>
      <el-table-column prop="title" label="题目" show-overflow-tooltip></el-table-column>
      <el-table-column prop="create" label="学生答案" width="150">
        <template #default="scope">
          <img :src="scope.row.url" alt="" width="72" height="40" @click="seeVideo(scope.row)" style="cursor: pointer">
        </template>
      </el-table-column>
      <el-table-column label="评语" width="500" style="position: relative;">
        <template #default="scope">
          <el-input v-model="scope.row.content" placeholder="请输入评语" style="width: 80%; "></el-input>
          <el-link type="primary" :underline="false" style="margin-left: 8px;" @click="changeComment(scope.$index)">选择评语</el-link>
          <div v-if="scope.row.show" style="width: 384px; position: absolute; top:58px; border: 1px solid #dcdfe6; background: #fff; border-radius: 4px; z-index: 999;">
            <div v-for="item in commentArr" class="comment" @click="sureComment(scope.$index, item)">{{ item }}</div>
          </div>
        </template>
      </el-table-column>
      <el-table-column label="评分（最高分100分）" width="200">
        <template #default="scope">
          <el-input-number v-model="scope.row.score" controls-position="right" :min="0.5" :max="100" :precision="0" class="input-number-left" style="width: 140px"></el-input-number>
          <el-link :underline="false" v-if="scope.row.type === 1" type="primary" @click="sureBtn(scope.row, scope.$index)" style="margin-left: 10px">确定</el-link>
        </template>
      </el-table-column>
    </el-table>

    <el-dialog :title="`${studentName}的作业`" :visible.sync="dialogFormVisible" width="615" @close="closeDialog">
      <video :src="videoUrl" autoplay controls width="100%"></video>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      commentArr: [
        '精彩！你展现了很好的创意和才华。',
        '内容丰富，清晰明了，让人容易理解。',
        '表现出色，展示了深厚的专业知识和技能。',
        '组织有序，逻辑清晰，展示了良好的思维能力。',
        '有条不紊，展示了出色的时间管理和执行能力。',
        '细节考虑周到，展示了对任务的全面思考和准备。',
        '展示了积极的学习态度和对课题的深入理解。',
        '展现了团队合作精神，有效地与他人合作完成任务。',
        '呈现了出色的表达能力和沟通技巧，让观众易于理解。',
        '展示了持续进步的态度，表现出对反馈和改进的积极态度。',
      ],
      list: [
        {
          id: 1,
          name: 'chatgpt',
          title: '上传一个预热视频',
          url: require('@/assets/image/teacherDataCenter/video4-img.png'),
          video: require('@/assets/image/teacherDataCenter/video4.mp4'),
          content: '',
          score: void 0,
          type: 1,
          show: false,
        },
        {
          id: 2,
          name: 'xiumuzidiao',
          title: '上传一个预热视频',
          url: require('@/assets/image/teacherDataCenter/video-img.png'),
          video: require('@/assets/image/teacherDataCenter/video4.mp4'),
          content: '',
          score: void 0,
          type: 1,
          show: false,
        },
        {
          id: 3,
          name: 'feifei168',
          title: '上传一个预热视频',
          url: require('@/assets/image/teacherDataCenter/video-img1.png'),
          video: require('@/assets/image/teacherDataCenter/video4.mp4'),
          content: '',
          score: void 0,
          type: 1,
          show: false,
        },
      ],
      videoUrl: '',
      studentName: '',
      dialogFormVisible: false
    }
  },
  methods: {
    changeComment(index) {
      this.list[index].show = !this.list[index].show;
    },
    sureComment(index, data) {
      this.list[index].content = data;
      this.list[index].show = false;
    },
    sureBtn(val, idx) {
      if (!val.score) {
        return this.$message.warning('请输入评分')
      }
      val.type = 2
      this.$message.success('评分成功')
    },
    seeVideo(val) {
      this.dialogFormVisible = true
      this.videoUrl = val.video
      this.studentName = val.name
    },
    closeDialog() {
      this.videoUrl = ''
      this.studentName = ''
    }
  }
}
</script>

<style scoped lang="scss">
.course-box {
  padding: 30px 30px 0;
  height: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  .operate-box {
    text-align: right;
  }
  .comment {
    padding: 0 8px;
    line-height: 30px;
    &:hover {
      background: #dcdfe6;
      cursor: pointer;
    }
  }
}
::v-deep .customTable {
  .el-table .cell div {
    display: inline-block;
  }
  .has-gutter .el-table__cell {
    color: #332d42 !important;
    background-color: #f0f3fc !important;
  }
  .cell {
    //line-height: normal;
    //white-space: nowrap;
  }
  .el-table__empty-block {
    display: none;
  }
}
</style>